import { BehaviorSubject } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { Account } from '@app/models/authentication/account.model';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { ScrollHomeService } from '@app/services/scroll-home/scroll-home.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class LoginObservable {
    private readonly localStorageService: LocalStorageService =
        inject(LocalStorageService);
    private readonly scrollHomeService: ScrollHomeService =
        inject(ScrollHomeService);
    private readonly router: Router = inject(Router);

    private dataSubject = new BehaviorSubject<Account | null>(null);
    data$ = this.dataSubject.asObservable();

    checkLocalStoreData(): void {
        const data: string | null = this.localStorageService.getItem(
            'account',
            true
        );
        if (data) this.dataSubject.next(JSON.parse(data));
    }

    updateData(data: Account | null): void {
        if (data)
            this.localStorageService.setItem('account', JSON.stringify(data));
        else this.removeLocaStore();

        this.dataSubject.next(data);
    }

    getData(): Account | null {
        return this.dataSubject.value;
    }

    removeLocaStore(): void {
        this.localStorageService.removeItem('account');
        this.localStorageService.removeItem('refreshToken');
        this.localStorageService.removeItem('token');
        this.scrollHomeService.resetValues();
        this.localStorageService.removeItem('dataPageNotification');
        this.router.navigate(['autenticacion/iniciarSesion']);
    }
}
