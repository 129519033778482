import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CryptoService {
    constructor() {}

    decrypted(data: string): any {
        // TODO: revisar que no estén pasando un string vacío por ejemplo esto
        //  const dataUrl = this.activatedRoute.snapshot.queryParamMap.get('data')! || '';
        if (data === '' || data === null || data === undefined) {
            return '';
        }

        try {
            const bytes = CryptoJS.AES.decrypt(data, environment.KEY_CRYPTO);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            return '';
        }
    }

    encrypted(data: any): any {
        return CryptoJS.AES.encrypt(
            JSON.stringify(data),
            environment.KEY_CRYPTO
        ).toString();
    }
}
