import { Injectable } from '@angular/core';
import { TYPE_MATCH_ENUM } from '@app/enums/barter/type-match.enum';
import { DataPage } from '@app/models/data-page.model';

@Injectable({
    providedIn: 'root',
})
export class ScrollHomeService {
    public scrollPosition = 0;
    public items: any[] = [];
    public page = 0;

    public categories: any[] = [];
    public plusSearch: any[] = [];
    public populate: any[] = [];
    public basedOnYourSearches: any[] = [];

    public optionView: TYPE_MATCH_ENUM = TYPE_MATCH_ENUM.exchanges;
    public backgroundColor: '#65328F' | '#33BDB5' = '#65328F';

    resetValues() {
        this.scrollPosition = 0;
        this.items = [];
        this.page = 0;
    }

    resetDataCategory() {
        this.categories = [];
        this.plusSearch = [];
        this.populate = [];
        this.basedOnYourSearches = [];
    }
}
